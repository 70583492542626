


/*===============================
    38.CONTACT US css 
================================*/

.contact-us-area{
    margin-bottom: 247px;
    & .contact-overlay{
        padding-top: 112px;
        background-color: rgba(12, 90, 219,.9);
        & .section-title{
            & span{
                color: $white;
                letter-spacing: 3px;
            }
            & .title{
                font-size: 55px;
                line-height: 65px;
                color: $white;
                padding-bottom: 35px;
                @media #{$lg} {
                    font-size: 44px;
                    line-height: 54px;
                }
                @media #{$xs} {
                    font-size: 30px;
                    line-height: 40px;
                }
                @media #{$sm} {
                    font-size: 36px;
                    line-height: 46px;
                }
                &::before{
                    display: none;
                }
            }
        }
        & .contact-details{
            background: $white;
            box-shadow: 0px 16px 32px 0px rgba(228, 228, 228, 0.56);
            padding: 60px;
            margin-bottom: -165px;
            @media #{$md} {
                display: block !important;
            }
            @media #{$xs} {
                display: block !important;
                padding: 30px;
            }
            @media #{$xs} {
                display: block !important;
            }
            & .contact-thumb{
                width: 80%;
                @media #{$lg} {
                    width: 100%;
                }
                @media #{$md} {
                    width: 100%;
                }
                @media #{$xs} {
                    width: 100%;
                }
                & img{
                    width: 100%;
                }
            }
            & .contact-form-area{
                margin-left: 40px;
                @media #{$md} {
                    margin-left: 0;
                }
                @media #{$xs} {
                    margin-left: 0;
                }
                & .input-title{
                    margin-top: -13px;
                    & .title{
                        font-size: 40px;
                        color: $black;
                        line-height: 50px;
                        padding-right: 40px;
                        @media #{$lg} {
                            font-size: 34px;
                            line-height: 44px;
                        }
                        @media #{$md} {
                           padding-right: 0;
                           padding-top: 40px;
                        }
                        @media #{$xs} {
                           padding-right: 0;
                           padding-top: 30px;
                           font-size: 26px;
                           line-height: 36px;
                        }
                        @media #{$sm} {
                           padding-right: 0;
                           padding-top: 40px;
                           font-size: 30px;
                           line-height: 40px;
                        }

                    }
                }
                & .input-box{
                    position: relative;
                    & input{
                        width: 100%;
                        border: 0;
                        border-bottom: 2px solid $border-color;
                        line-height: 45px;
                        &::placeholder{
                            opacity: 1;
                            color: #616161;
                            display: 700px;
                            text-transform: capitalize;
                        }
                    }
                    & textarea{
                        width: 100%;
                        height: 90px;
                        border: 0;
                        border-bottom: 2px solid $border-color;
                        resize: none;
                        &::placeholder{
                            opacity: 1;
                            color: #616161;
                            display: 700px;
                            text-transform: capitalize;
                        }
                    }
                    & button{
                        margin-top: 30px;
                        line-height: 60px;
                        padding: 0 40px;
                        font-size: 15px;
                        background: $theme-color;
                        color: $white;
                        border-color: $theme-color;
                        & i{
                            padding-left: 5px;
                        }
                    }
                    & > i{
                        position: absolute;
                        right: 0;
                        top: 15px;
                        color: $theme-color;
                    }
                }
            }
        }
    }
}

