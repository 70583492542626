


/*===============================
    34.CONTACT css 
================================*/


.contact-details-area{
	& .contact-info{
		border: 1px solid $border-color-2;
		@media #{$xs} {
			margin-right: 0;
		}
		& .contact-item-1{
			display: flex;
			@media #{$xs} {
				display: block;
			}
			@media #{$sm} {
				display: flex;
			}
			& .contact-info-item{
				border: 1px solid $border-color-2;
				width: 100%;
				padding: 15px 0 50px;
				& i{
					height: 80px;
					width: 80px;
					border-radius: 50%;
					background: $theme-color;
					text-align: center;
					line-height: 80px;
					color: $white;
					font-size: 24px;

				}
				& .title{
					font-size: 24px;
					padding-top: 25px;
				}
				& p{
					font-size: 18px;
					color: $text-color-3;
					padding-top: 5px;
				}

			}
		}
	}
	& .map-area{
		& iframe{
			width: 100%;
			height: 550px;

		}
	}
}


