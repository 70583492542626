


/*===============================
    35.WHO WE ARE css 
================================*/

.who-we-are-area{
	position: relative;
	z-index: 5;
	& .section-title{
		& .title{
			font-size: 55px;
			color: $black;
			margin-bottom: 38px;
            @media #{$lg} {
                font-size: 42px;
                line-height: 52px;
            }
            @media #{$md} {
                font-size: 46px;
                line-height: 56px;
                margin-bottom: 0;
            }
            @media #{$xs} {
                font-size: 26px;
                line-height: 36px;
                margin-bottom: 0;
            }
            @media #{$sm} {
                font-size: 40px;
                line-height: 50px;
                margin-bottom: 0;
            }
			&::before{
				display: none;
			}
		}
		& p{
			font-size: 15px;
			padding-right: 15px;
			line-height: 35px;
			color: #616161;
			padding-top: 25px;
		}
	}
	& .what-we-do-item{
		box-shadow: 0px 10px 29.7px 0.3px rgba(225, 225, 225, 0.64);
		padding: 45px 20px 50px;
		position: relative;
		overflow: hidden;
		@include transition(0.3s);
		background-color: $white;
		z-index: 5;
		& > svg{
			font-size: 6rem;
			color: $theme-color;
			padding-bottom: 35px;
			@include transition(0.3s);
		}
		& .title{
			font-size: 22px;
			color: $black;
			@include transition(0.3s);
            @media #{$lg} {
                font-size: 18px;
            }
		}
		& p{
			color: #616161;
			font-size: 15px;
			padding-top: 10px;
			@include transition(0.3s);
		}
		& a{
			height: 40px;
			width: 40px;
			text-align: center;
			line-height: 40px;
			background: $theme-color;
			color: $white;
			font-size: 14px;
			right: 0;
			bottom: 0;
			position: absolute;
			@include transition(0.3s);
		}
		&:hover{
			background: $theme-color;
			& svg{
				color: $white;
			}
			& .title{
				color: $white;
			}
			& p{
				color: $white;
			}
			& a{
				background: $white;
				& svg{
					color: $theme-color;
				}
			}
		}
		&::before{
			position: absolute;
			content: "";
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-image: url(../images/what-we-are-dot.png);
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			z-index: -1;

		}
	}
	& .what-we-are-shape-1{
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		@media #{$xs} {
			width: 40%;
		}
	}
	& .what-we-are-shape-2{
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
		@media #{$xs} {
			width: 40%;
		}
	}
}



