


/*===========================
    14.LEADERSHIP css 
===========================*/

.leadership-area{
	& .leadership-item{
		position: relative;
		padding-bottom: 60px;
		min-width: 350px !important;
		min-height: 343px !important;
		& img{
			width: 100%;
			border: 10px solid $white;

		}
		& .leadership-content{
			position: absolute;
			right: 0;
			bottom: 0px;
			width: 90%;
			background: $white;
			padding-top: 20px;
			padding-bottom: 23px;
			padding-left: 40px;
			box-shadow: 0px 8px 16px 0px rgba(132, 190, 255, 0.1);
			@media #{$sm} {
				padding-left: 20px;
			}
			& .title{
				font-size: 24px;
				@media #{$sm} {
					font-size: 20px;
				}
			}
			& span{
				color: $theme-color;
				font-weight: 700;
				padding-top: 9px;
			}

		}
	}

	& .leadership-active{
		& .slick-arrow{
			position: absolute;
			transform: translateX(-50%);
			bottom: -200px;
			height: 80px;
			width: 80px;
			background: $white;
			text-align: center;
			line-height: 80px;
			box-shadow: 0px 8px 16px 0px rgba(132, 190, 255, 0.1);
			cursor: pointer;
			& i{
				font-size: 20px;
				@include transition(0.3s);
			}
			&.prev{
				left: 48%;
			}
			&.next{
				right: 39%;
				&::before{
					position: absolute;
					content: '';
					width: 2px;
					height: 50px;
					left: -1px;
					bottom: -12%;
					background:
					#e9f1ff;
					transform: translate(-50%, -50%);
				}

				@media #{$lg} {
					right: 36%;
				}
			}
			&:hover{
				& i{
					color: $theme-color;
				}
			}

		}
	}
	&.padding{
		padding-bottom: 252px;
	}
}


